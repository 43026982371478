@font-face {
font-family: '__PirataOneGloomhaven_747a1c';
src: url(/_next/static/media/7a0430e6b6669ae2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__PirataOneGloomhaven_Fallback_747a1c';src: local("Arial");ascent-override: 124.96%;descent-override: 34.66%;line-gap-override: 0.00%;size-adjust: 80.51%
}.__className_747a1c {font-family: '__PirataOneGloomhaven_747a1c', '__PirataOneGloomhaven_Fallback_747a1c';font-weight: 400
}.__variable_747a1c {--font-pirata-one: '__PirataOneGloomhaven_747a1c', '__PirataOneGloomhaven_Fallback_747a1c'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Philosopher_09c703';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/469549a68a4e55e7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Philosopher_09c703';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9794175766457f6e-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Philosopher_09c703';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a540fab09d7ccd17-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Philosopher_09c703';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c910a7d5137986c1-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Philosopher_09c703';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7a86e2430d293a4d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Philosopher_Fallback_09c703';src: local("Arial");ascent-override: 93.96%;descent-override: 22.97%;line-gap-override: 0.00%;size-adjust: 95.78%
}.__className_09c703 {font-family: '__Philosopher_09c703', '__Philosopher_Fallback_09c703';font-weight: 700;font-style: normal
}.__variable_09c703 {--font-philosopher: '__Philosopher_09c703', '__Philosopher_Fallback_09c703'
}

